<script setup lang="ts">
import { mdiPrinterPosOff } from "@mdi/js";

// composables
const { t } = useI18n();
const { isConnected, tryConnect, loading } = usePrintHelper();
</script>

<template>
  <MTooltip
    v-if="!isConnected"
    :text="t('print-app-connect')"
  >
    <template #activator="{ props: activatorProps }">
      <span
        class="hidden sm:block"
        v-bind="activatorProps"
      >
        <MBtn
          icon
          size="small"
          color="error"
          variant="tonal"
          :loading="loading"
          @click="tryConnect"
        >
          <MIcon :icon="mdiPrinterPosOff" />
        </MBtn>
      </span>
    </template>
  </MTooltip>
</template>
