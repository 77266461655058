<script setup lang="ts">
import { VNavigationDrawer } from "vuetify/components/VNavigationDrawer";
import {
  mdiPin,
  mdiReceiptText,
  mdiViewList,
  mdiCash,
  mdiAccountCircle,
  mdiCog,
} from "@mdi/js";
import type { RoutesNamedLocations } from "@typed-router";

// icons imports

// composables
const { t } = useI18n();
const draftInvoiceStore = useDraftInvoiceStore();
const route = useRoute();

// v-model
const modelValue = defineModel<boolean>({
  default: false,
});

// can be used to add links to the sidebar
// either as a single link or as a group of links
type Link =
  | {
      title: string;
      to: RoutesNamedLocations;
      icon?: string;
      subLinks?: never;
      exact?: boolean;
    }
  | {
      title: string;
      to?: never;
      icon?: string;
      subLinks: {
        title: string;
        to: RoutesNamedLocations;
        icon?: string;
        exact?: boolean;
      }[];
    };

const drawerLinks = computed<Link[]>(() => [
  {
    title: t("createInvoice"),
    to: { name: "dashboard" },
    icon: mdiPin,
    exact: true,
  },
  {
    title: t("invoicesList"),
    to: { name: "dashboard-invoices" },
    icon: mdiViewList,
  },
  {
    title: t("cashPosition"),
    to: { name: "dashboard-cash-position" },
    icon: mdiCash,
  },
  {
    title: t("draft-invoices"),
    subLinks: draftInvoiceStore.draftInvoices.map((invoice) => ({
      title: `${t("invoice-num")} #${invoice.id}`,
      icon: mdiReceiptText,
      to: { name: "dashboard", query: { invoiceId: invoice.id } },
      exact: true,
    })),
  },
]);
</script>

<template>
  <VNavigationDrawer v-model="modelValue">
    <MList v-if="!!drawerLinks">
      <template
        v-for="(link, index) in drawerLinks"
        :key="index"
      >
        <ListItemLink
          v-if="!link.subLinks"
          :key="`${link.title}-${index}`"
          :route="link.to"
          :prependIcon="link.icon"
          :title="link.title"
          :exact="!!link.exact"
          :active="link.to.name === route.name"
        />

        <MListGroup v-else>
          <template #activator="{ props: slotProps }">
            <MListItem v-bind="slotProps">
              <template #title>
                {{ link.title }}
              </template>
            </MListItem>
          </template>
          <ListItemLink
            v-for="(subLink, subIndex) in link.subLinks"
            :key="`${link.title}-${subLink.title}-${subIndex}`"
            :route="subLink.to"
            :prependIcon="subLink.icon"
            :title="subLink.title"
            :exact="!!subLink.exact"
          />
        </MListGroup>
      </template>
    </MList>
    <template #append>
      <MDivider />
      <MList>
        <ListItemLink
          :prependIcon="mdiCog"
          :title="t('settings')"
          :route="{ name: 'dashboard-settings' }"
        />
        <ListItemLink
          :prependIcon="mdiAccountCircle"
          :title="t('account')"
          :route="{ name: 'dashboard-account' }"
        />
      </MList>
      <MDivider />
      <div class="my-12"></div>
    </template>
  </VNavigationDrawer>
</template>

<style>
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none !important;
}

.v-navigation-drawer__content {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>
