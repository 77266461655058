<script setup lang="ts">
import { VAppBar } from "vuetify/components/VAppBar";
import { mdiMenu } from "@mdi/js";

const emit = defineEmits<{
  (e: "toggle:sidebar"): void;
}>();

const { isLoggedIn } = useAuthStorage();
const { theme } = useThemeStorage();
const route = useRoute();
const { locale } = useI18n();
const { showDownloadBtn } = usePrintHelper();
</script>

<template>
  <VAppBar elevation="2">
    <template #prepend>
      <MBtn
        v-if="isLoggedIn && route.name?.startsWith('dashboard')"
        type="button"
        icon
        @click="emit('toggle:sidebar')"
      >
        <MIcon :icon="mdiMenu" />
      </MBtn>
      <BtnLink
        :route="{ name: 'dashboard' }"
        color="default"
        :active="false"
      >
        <img
          :src="`/images/logo-${locale}-${theme}.svg`"
          class="h-[2.2rem] object-scale-down"
        />
      </BtnLink>
    </template>
    <template #append>
      <div class="flex items-center gap-x-4">
        <MResponsiveActions horizontal>
          <template #1>
            <AppDownload />
          </template>
          <template
            v-if="showDownloadBtn"
            #2
          >
            <PrintAppDownload />
          </template>
        </MResponsiveActions>
        <PrintAppConnect />
        <AppOfflineChip />

        <AppSyncChip />
      </div>
    </template>
  </VAppBar>
</template>
