import { locales, type Locale } from "@mono/constants/lib/locale";

const initLocale = () => {
  // get localStorage locale
  const localStorageLocale = localStorage.getItem("locale") as Locale | null;
  // detect user browser language
  const browserLocale = navigator.language.split("-")[0];
  if (locales.includes(localStorageLocale as Locale)) {
    // if locale is set in localStorage, init user locale with localStorage locale
    return localStorageLocale!;
  } else if (locales.includes(browserLocale as Locale)) {
    // if no locale is set in localStorage, init user locale with browser locale
    localStorage.setItem("locale", browserLocale);
    return browserLocale;
  } else {
    // clear localStorage locale
    localStorage.removeItem("locale");
    return "en";
  }
};

export const useLocaleWatcher = () => {
  const { locale } = useI18n();

  locale.value = initLocale();
  watch(locale, (newLocale) => {
    localStorage.setItem("locale", newLocale);
  });
};
