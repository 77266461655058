<script setup lang="ts">
import { mdiPrinterPosOutline } from "@mdi/js";

// composables
const { t } = useI18n();
const { downloadUrl } = usePrintHelper();
</script>

<template>
  <MBtn
    variant="plain"
    color="secondary"
    class="flex items-center"
    :href="downloadUrl"
  >
    <MIcon
      start
      :icon="mdiPrinterPosOutline"
    />
    {{ t("install-print") }}
  </MBtn>
</template>
