<script setup lang="ts">
import { mdiAccessPointOff } from "@mdi/js";

// composables
const { t } = useI18n();
const { online, isOnline } = useNetworkCheck();

// states
const loading = ref(false);

// methods
const attemptRefresh = async () => {
  loading.value = true;
  await isOnline();
  loading.value = false;
};
</script>

<template>
  <MTooltip
    location="bottom"
    :text="t('youre-currently-offl')"
  >
    <template #activator="{ props: activatorProps }">
      <MBtn
        v-if="!online"
        v-bind="activatorProps"
        color="error"
        icon
        size="small"
        variant="tonal"
        :loading="loading"
        @click="attemptRefresh"
      >
        <MIcon :icon="mdiAccessPointOff" />
      </MBtn>
    </template>
  </MTooltip>
</template>
