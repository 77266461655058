<script setup>
import { mdiCloudRefreshOutline, mdiRefresh } from "@mdi/js";
const { $pwa } = usePwa();
const snack = useSnack();
const { t } = useI18n();

// states
const banner = ref(false);

// watchers
watchEffect(() => {
  if ($pwa.offlineReady) {
    snack.info(t("app-is-ready-for-off"));
  }
});

watch(
  () => $pwa.needRefresh,
  (value) => {
    banner.value = value;
  }
);
</script>

<template>
  <MBanner
    v-model="banner"
    :text="t('new-content-availabl')"
    :icon="mdiCloudRefreshOutline"
    sticky
    location="bottom left"
    class="md:m-4"
    :maxWidth="500"
    rounded
  >
    <template #actions>
      <MBtn
        variant="text"
        color="secondary"
        icon
        block
        @click="
          $pwa.updateServiceWorker();
          banner = false;
        "
      >
        <MIcon :icon="mdiRefresh" />
      </MBtn>
    </template>
  </MBanner>
</template>
