<script setup lang="ts">
import { mdiClose } from "@mdi/js";

const { t } = useI18n();

const hide = ref(false);
</script>

<template>
  <div
    v-if="!hide"
    class="bg-secondary flex flex-col justify-between gap-y-2 px-4 py-4 sm:flex-row sm:items-center"
  >
    <p>{{ t("this-is-a-demo-versi") }}</p>
    <MBtn
      variant="outlined"
      size="small"
      color="warning"
      type="button"
      @click="hide = true"
    >
      <MIcon
        start
        :icon="mdiClose"
      />
      {{ t("dismiss") }}
    </MBtn>
  </div>
</template>
