<script setup lang="ts">
import { mdiDownload } from "@mdi/js";

// composables
const { t } = useI18n();
const { $pwa } = usePwa();
</script>

<template>
  <MBtn
    v-if="!$pwa?.isPWAInstalled && $pwa?.showInstallPrompt"
    variant="plain"
    color="primary"
    @click="$pwa?.install()"
  >
    <MIcon
      start
      :icon="mdiDownload"
    />
    {{ t("install-app") }}
  </MBtn>
</template>
