<script setup lang="ts">
import { VBanner } from "vuetify/components/VBanner";

interface Props {
  location?: Anchor;
  sticky?: boolean;
  color?: string;
  lines?: "one" | "two" | "three";
  stacked?: boolean;
  text?: string;
  border?: boolean;
  icon?: string;
  maxWidth?: string | number;
  position?: "sticky" | "static" | "relative" | "fixed" | "absolute";
  rounded?: string | number | boolean;
}

// Props
const props = withDefaults(defineProps<Props>(), {
  location: undefined,
  sticky: false,
  color: "primary",
  lines: "one",
  stacked: true,
  text: undefined,
  border: false,
  icon: undefined,
  maxWidth: undefined,
  position: undefined,
  rounded: undefined,
});

// v-model
const show = defineModel<boolean>({
  required: false,
});
</script>

<template>
  <VBanner
    v-if="show"
    v-bind="props"
    style="flex: unset"
  >
    <slot />

    <template
      v-if="$slots.actions"
      #actions
    >
      <slot name="actions" />
    </template>

    <template
      v-if="$slots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>
  </VBanner>
</template>
