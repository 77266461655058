<script setup lang="ts">
import { mdiTwitter, mdiLinkedin, mdiInstagram, mdiYoutube } from "@mdi/js";

const { t } = useI18n();
const { isLoggedIn } = useAuthStorage();
const runtimeConfig = useRuntimeConfig();

const socials = [
  { icon: mdiTwitter, url: "https://twitter.com/mezanbooks" },
  { icon: mdiLinkedin, url: "https://www.linkedin.com/company/mezan-books" },
  { icon: mdiInstagram, url: "https://instagram.com/mezanbooks" },
  {
    icon: mdiYoutube,
    url: "https://youtube.com/channel/UC_AR0HRbcBa_HRu6MO04iAQ",
  },
];
</script>

<template>
  <div style="background-color: rgba(var(--v-theme-surface))">
    <div
      class="mt-6 w-full items-center justify-between px-4 text-center lg:my-2 lg:flex lg:text-start"
    >
      <div class="mb-4 lg:mb-0">
        <span class="mb-2 block text-sm lg:mb-0 lg:inline-block">
          &copy;
          {{ new Date().getFullYear() }}
          {{ t("2021-mezan-saudi-ara") }}
          -
          {{ runtimeConfig.public.APP_VERSION }}
        </span>
        <span class="hidden px-2 lg:inline-block"></span>
        <span class="mb-2 block lg:mb-0 lg:inline-block">
          <MBtn
            href="https://mezan.sa/terms"
            target="_blank"
            color="default"
            size="small"
            variant="text"
          >
            {{ t("termsOfService") }}
          </MBtn>
        </span>
        <span class="mb-2 block lg:mb-0 lg:inline-block">
          <MBtn
            href="https://mezan.sa/privacy"
            target="_blank"
            color="default"
            size="small"
            variant="text"
          >
            {{ t("privacyPolicy") }}
          </MBtn>
        </span>
      </div>
      <div>
        <div class="mb-4 block lg:mb-0 lg:inline-block">
          <AppSettings />
        </div>
        <span class="px-1"></span>
        <div
          v-if="!isLoggedIn"
          class="block lg:inline-block"
        >
          <template
            v-for="(social, index) in socials"
            :key="index"
          >
            <MBtn
              :href="social.url"
              size="small"
              color="default"
              variant="text"
              icon
              target="_blank"
            >
              <MIcon :icon="social.icon" />
            </MBtn>
            <span
              v-if="index !== socials.length - 1"
              class="px-1"
            ></span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
